import { redirect } from 'react-router-dom'
import { API_LOGIN } from '../config'

export class Login {
  base_url = API_LOGIN.URL

  async login (data) {
    let rs = {}
    try {
      const url = `${this.base_url}/${API_LOGIN.ROUTES.LOGIN}`
      const param = {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password
        })
      }

      const response = await fetch(url, param)
        .then((data) => {
          return data
        })
        .catch((err) => { if (err) return false })
      if (response) {
        rs = await response.json()
        if (!rs) {
          this.removeTokens()
          return redirect('/')
        } else {
          if (rs.code === 10) {
            this.setToken(rs.data.token)
            this.setRefreshToken(rs.data.refreshToken)
          } else {
            this.removeTokens()
          }
        }
        return rs
      } else {
        return { code: 0, message: 'Error en el server, por favor reintente luego' }
      }
    } catch (error) {
      return { code: 0, message: 'Error en el server, por favor reintente luego' }
    }
  }

  setToken (token) {
    localStorage.setItem(API_LOGIN.LOCALSTORAGE.TOKEN, token)
  }

  setRefreshToken (token) {
    localStorage.setItem(API_LOGIN.LOCALSTORAGE.REFRESH, token)
  }

  getToken () {
    return localStorage.getItem(API_LOGIN.LOCALSTORAGE.TOKEN)
  }

  getRefreshToken () {
    return localStorage.getItem(API_LOGIN.LOCALSTORAGE.REFRESH)
  }

  removeTokens () {
    localStorage.removeItem(API_LOGIN.LOCALSTORAGE.TOKEN)
    localStorage.removeItem(API_LOGIN.LOCALSTORAGE.REFRESH)
  }
}
