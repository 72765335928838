import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home, Singup, Singin, Forgot } from '../sites/web/pages'
import { webLayout } from '../sites/web/layouts'

export function WebRouter () {
  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    )
  }
  return (
          <Routes>
            <Route path='/login' element={loadLayout(webLayout, Singin)} />
            <Route path='/registrarse' element={loadLayout(webLayout, Singup)} />
            <Route path='/recuperar' element={loadLayout(webLayout, Forgot)} />
            <Route path='/' element={loadLayout(webLayout, Home)} />
          </Routes>
  )
}
