import React from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import AppBar from '@mui/material/AppBar'
import CameraIcon from '@mui/icons-material/PhotoCamera'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { coloresThemeAdmin } from '../../../config'

const theme = createTheme(coloresThemeAdmin)

export function AdminLayout (props) {
  const { children } = props
  const App = process.env.REACT_APP_NAME
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            {App}
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
          { children }
      </main>
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
      </Box>
      {/* End footer */}
      <Copyright></Copyright>
    </ThemeProvider>
  )
}

function Copyright (props) {
  const baseUrl = process.env.REACT_APP_BASE_URL
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={baseUrl}>
        naranja.dilfer.com.ar
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
