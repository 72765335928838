
export async function goAPI (url, param, debug = false) {
  let rs = {}
  const response = await fetch(url, param)
    .then((data) => {
      return data
    })
    .catch((err) => { if (err) return false })
  if (debug) { console.log(response) }
  if (response) {
    rs = await response.json()
    if (debug) { console.log(rs) }
    if (!rs) {
      rs = { code: 0, message: 'Error en el server, por favor reintente luego', data: {} }
    }
    return rs
  } else {
    return { code: 0, message: 'Error en el server, por favor reintente luego', data: {} }
  }
}

export function toOptionSelect (rs) {
  let text
  if (rs?.data && rs.data.length > 0) {
    rs.data.forEach((value) => (
      text += <option key={value.valor} value={value.valor} >{value.label}</option>
    ))
  } else {
    text += <option value="0" >No hay Valores</option>
  }
  return text
}
