import React from 'react'
import { Tabla } from '../../../share'

export function Users () {
  const header = ['nombre', 'apellido']
  const users = [{ nombre: 'daniel', apellido: 'bertoni' }]
  const tablaUsuarios = Tabla('Usuarios', header, users)
  return (
    <div>{tablaUsuarios}</div>
  )
}
