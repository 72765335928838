import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { ENV } from '../../config'

export function NavBarWeb () {
  const appname = ENV.APP_NAME
  const barraMenu = [
    {
      label: 'Accesos Directos',
      link: '/links',
      target: '_self'
    },
    {
      label: 'Monitoreo',
      link: 'http://zabbix01.dilfer.local:8080',
      target: '_blank'
    }
  ]
  return (
      <AppBar
          position="static"
          color="primary"
          elevation={0}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
            <Toolbar sx={{ flexWrap: 'wrap' }}>
                <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                    { appname }
                </Typography>
                <nav>
                    {barraMenu.map((value, key) => (
                        <Link
                        key={key}
                        variant="button"
                        color="text.primary"
                        href={value.link}
                        target={value.target}
                        sx={{ my: 1, mx: 1.5 }}
                        >
                            {value.label}
                        </Link>
                    ))}
                </nav>
                <ButtonGroup variant="contained">
                    <Button href="/login">
                        Login
                    </Button>
                    <Button href="/registrarse">
                        Registrarse
                    </Button>
                </ButtonGroup>
            </Toolbar>
        </AppBar>
  )
}
