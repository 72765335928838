import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useFormik } from 'formik'
import { InitialValues, ValidacionSingin } from './Singin.form'
import { AlertaCritico } from '../../../../share'
import { Login } from '../../../../../servicios'
import { useNaranja } from '../../../../../hooks'

const LoginController = new Login()

export function Singin () {
  const { login } = useNaranja()
  const [errorForm, setErrorForm] = React.useState('')
  const formik = useFormik({
    initialValues: InitialValues(),
    validationSchema: ValidacionSingin(),
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {
        setErrorForm('')
        const rs = await LoginController.login(formValues)
        console.log(rs)
        if (rs.code !== 10) {
          setErrorForm(rs.message)
        } else {
          login(rs.data.token)
          window.location.replace('/admin/')
        }
      } catch (err) {
        setErrorForm(err)
      }
    }
  })

  const AlertaValidacion = AlertaCritico(errorForm)

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Ingresar
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={formik.handleChange}
              value={formik.values.email}
              helperText={formik.errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={formik.handleChange}
              value={formik.values.password}
              helperText={formik.errors.password}
            />
            {AlertaValidacion}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Ingresar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Olvido su Contraseña?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/registrarse" variant="body2">
                  {'Aun no tiene cuenta? Registrese'}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  )
}
