import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import { AdminRouter, WebRouter } from './router'
import { NaranjaProvider } from './contexts'

function App () {
  return (
    <NaranjaProvider>
      <BrowserRouter>
          <AdminRouter/>
        <WebRouter/>
      </BrowserRouter>
    </NaranjaProvider>
  )
}

export default App
