
export const API_LOGIN = {
  URL: process.env.REACT_APP_URL_LOGIN,
  ROUTES: {
    REGISTRO: 'register',
    LOGIN: 'login',
    USER: 'login/me'
  },
  LOCALSTORAGE: {
    TOKEN: 'token',
    REFRESH: 'refresh'
  }
}

export const API_RECURSOS = {
  URL: process.env.REACT_APP_URL_API + '/recursos',
  ROUTES: {
    LOCAL: 'local'
  },
  LOCALSTORAGE: {
    TOKEN: 'token',
    REFRESH: 'refresh'
  }
}
