import { API_RECURSOS } from '../../config'
import { goAPI, toOptionSelect } from '../utils'

export class Grupo {
  base_url = API_RECURSOS.URL

  async getAll (toOption = false) {
    try {
      const url = `${this.base_url}/${API_RECURSOS.ROUTES.GRUPO}`
      const param = {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
      if (toOption) {
        return toOptionSelect(goAPI(url, param, true))
      } else {
        return goAPI(url, param, true)
      }
    } catch (error) {
      return { code: 0, message: 'Error en el server, por favor reintente luego', data: {} }
    }
  }
}
