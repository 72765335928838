import * as React from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { People, Settings } from '@mui/icons-material'

const Apps = [
  {
    nombre: 'Usuarios',
    link: '/admin/users',
    icon: <People sx={{ fontSize: 80 }} color="primary" > </People>,
    description: 'Adminstracion total de usuarios',
    buttonText: 'Ingresar >>'
  },
  {
    nombre: 'Configuraciones',
    link: '/admin/config',
    icon: <Settings sx={{ fontSize: 80 }} color="custom2"/>,
    description: 'Configuraciones generales',
    buttonText: 'Ingresar >>'
  }
]

export function Menu () {
  return (
    <Container sx={{ py: 8 }} maxWidth="md">
        <Grid container spacing={4}>
        {Apps.map((card, idx) => (
            <Grid item key={idx} xs={12} sm={6} md={4}>
            <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <CardContent sx={{ flexGrow: 1 }}>
                {card.icon}
                <Typography gutterBottom variant="h5" component="h2">
                    {card.nombre}
                </Typography>
                <Typography>
                    {card.description}
                </Typography>
                </CardContent>
                <CardActions>
                <Button
                    size="small"
                    href={card.link}
                    >{card.buttonText}
                </Button>
                </CardActions>
            </Card>
            </Grid>
        ))}
        </Grid>
    </Container>
  )
}
