import { API_LOGIN } from '../config'

export class Me {
  base_url = API_LOGIN.URL

  async getMe (token) {
    try {
      const url = `${this.base_url}/${API_LOGIN.ROUTES.USER}`
      const param = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

      const response = await fetch(url, param)
      const rs = await response.json()
      if (rs) {
        return rs
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
