import { useEffect, useState, createContext } from 'react'
import { Me, Login } from '../servicios'

export const NaranjaContext = createContext()

export function NaranjaProvider (props) {
  const { children } = props
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const meController = new Me()
  const loginController = new Login()

  useEffect(() => {
    (async () => {
      const token = loginController.getToken()
      // const refresh = loginController.getRefreshToken()
      await login(token)
      setLoading(false)
    })()
  }, [])

  const login = async (accessToken) => {
    try {
      setToken(accessToken)
      setUser(accessToken)

      const rsMe = await meController.getMe(accessToken)
      if (rsMe.code === 10) {
        setUser(rsMe.data)
      } else {
        setUser(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const data = {
    Token: token,
    login,
    user
  }

  if (loading) return null

  return <NaranjaContext.Provider value={data} >{children}</NaranjaContext.Provider>
}
