import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { AddCircle, Edit, RemoveCircle } from '@mui/icons-material'

export function CRUD (titulo, data, links) {
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {titulo}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell align="right">
              <Button color='success' variant='contained' href={links.add} ><AddCircle/></Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell >{row.id}</TableCell>
              <TableCell >{row.nombre}</TableCell>
              <TableCell >{row.estado}</TableCell>
              <TableCell align="right">
                <Button variant='contained' color='custom1' href={links.edit}><Edit/></Button>
                <Button variant='contained' color='custom2' href={links.del}><RemoveCircle/></Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
