import * as Yup from 'yup'

export function InitialValues () {
  return {
    email: '',
    password: ''
  }
}

export function ValidacionSingin () {
  return Yup.object({
    email: Yup.string().email('Debe ser un email valido').required('El campo es obligatorio'),
    password: Yup.string().required('El campo es obligatorio').min(4, 'Debe ser de al menos 4 caracteres').max(12, 'Debe contener hasta 12 caracteres')
  })
}
