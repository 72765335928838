import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import GlobalStyles from '@mui/material/GlobalStyles'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { NavBarWeb } from '../../share'
import { coloresThemeWeb } from '../../../config'

const theme = createTheme(coloresThemeWeb)
export function webLayout (props) {
  const { children } = props

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <NavBarWeb></NavBarWeb>
      <main>
        { children }
      </main>
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
      </Box>
      {/* End footer */}
      <Copyright></Copyright>
    </ThemeProvider>
  )
}

function Copyright (props) {
  const baseUrl = process.env.REACT_APP_BASE_URL
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={baseUrl}>
        naranja.dilfer.com.ar
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
