export const coloresThemeAdmin = {
  palette: {
    primary: {
      main: '#F28C28'
    },
    secondary: {
      main: '#FF5F1F'
    },
    custom1: {
      main: '#E35335'
    },
    custom2: {
      main: '#FA8072'
    }
  }
}

export const coloresThemeWeb = {
  palette: {
    primary: {
      main: '#009E60'
    },
    secondary: {
      main: '#478778'
    },
    custom1: {
      main: '#DFFF00'
    },
    custom2: {
      main: '#E4D00A'
    }
  }
}
