import { API_RECURSOS } from '../../config'
import { goAPI } from '../utils'

export class Local {
  base_url = API_RECURSOS.URL

  async getAll () {
    try {
      const url = `${this.base_url}/${API_RECURSOS.ROUTES.LOCAL}`
      const param = {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
      return goAPI(url, param, true)
    } catch (error) {
      return { code: 0, message: 'Error en el server, por favor reintente luego', data: {} }
    }
  }

  async add (token, dataIn) {
    const data = JSON.stringify({
      nombre: dataIn.nombre,
      abrev: dataIn.abrev,
      id_grupo: dataIn.id_grupo
    })
    console.log(data)
    try {
      const url = `${this.base_url}/${API_RECURSOS.ROUTES.LOCAL}`
      const param = {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          body: data
        }
      }
      return goAPI(url, param, true)
    } catch (error) {
      return { code: 0, message: 'Error en el server, por favor reintente luego', data: {} }
    }
  }
}
