import React from 'react'
import Container from '@mui/material/Container'
import { Local } from '../../../../servicios'
import { CRUD } from '../../../share'

export function ConfigHome () {
  const rsLocales = async () => {
    return await Local.getAll()
  }
  console.log(rsLocales)

  const data = []
  if (rsLocales.data) {
    rsLocales.data.forEach(e => {
      const d = {
        id: e.id,
        nombre: e.nombre,
        estado: e.estado
      }
      data.push(d)
    })
  }
  const links = {
    add: 'config/local/new',
    edit: 'config/local/',
    del: 'config/local/'
  }
  const tablaLocales = CRUD('Locales', data, links)
  console.log(data)
  return (
    <Container sx={{ py: 8 }} maxWidth="md">
      {tablaLocales}
    </Container>
  )
}
