import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AdminLayout } from '../sites/admin/layouts'
import { Users, Menu, ConfigHome, ConfigLocal } from '../sites/admin/pages'
import { webLayout } from '../sites/web/layouts'
import { Singin } from '../sites/web/pages'
import { useNaranja } from '../hooks'

export function AdminRouter () {
  const { user } = useNaranja()
  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    )
  }
  return (
            <Routes>
              {!user
                ? (
                  <>
                    <Route path='/admin/*' element={ loadLayout(webLayout, Singin) } />
                  </>
                  )
                : (
                  <>
                    <Route path='/admin/' element={ loadLayout(AdminLayout, Menu)} />
                    <Route path='/admin/users' element={ loadLayout(AdminLayout, Users) } />
                    <Route path='/admin/config/' element={ loadLayout(AdminLayout, ConfigHome)} />
                    <Route path='/admin/config/local/:id' element={ loadLayout(AdminLayout, ConfigLocal)} />
                  </>
                  )
              }
            </Routes>
  )
};
