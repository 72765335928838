import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { AlertaCritico } from '../../../share'
import { Grupo, Local } from '../../../../servicios'
import { useNaranja } from '../../../../hooks'
import { BusinessCenter } from '@mui/icons-material'
import { FormControl, InputLabel, NativeSelect } from '@mui/material'

const LocalController = new Local()
const GrupoController = new Grupo()

export function ConfigLocal () {
  const [errorForm, setErrorForm] = React.useState('')
  const { Token } = useNaranja()
  const dataInit = {
    nombre: '',
    abrev: '',
    id_grupo: 2
  }
  const localForm = useFormik({
    initialValues: InitialValues(dataInit),
    validationSchema: ValidacionSingin(),
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {
        setErrorForm('')
        const rs = await LocalController.add(Token, formValues)
        console.log(rs)
        if (rs.code !== 10) {
          setErrorForm(rs.message)
        } else {
          window.location.replace('/admin/config/')
        }
      } catch (err) {
        setErrorForm(err)
      }
    }
  })

  const AlertaValidacion = AlertaCritico(errorForm)
  const opcionesGrupo = GrupoController.getAll(true)
  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.dark' }}>
            <BusinessCenter />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nuevo Local
          </Typography>
          <Box component="form" onSubmit={localForm.handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="nombre"
                label="Nombre"
                name="nombre"
                autoComplete="nombreLocal"
                autoFocus
                onChange={localForm.handleChange}
                value={localForm.values.nombre}
                helperText={localForm.errors.nombre}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="abrev"
              label="Abreviatura"
              id="abrev"
              autoComplete="abrevLocal"
              onChange={localForm.handleChange}
              value={localForm.values.abrev}
              helperText={localForm.errors.abrev}
            />
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="id_grupo">
                    Grupo
                </InputLabel>
                <NativeSelect
                    inputProps={{
                      name: 'id_grupo',
                      id: 'id_grupo',
                      value: localForm.values.id_grupo
                    }}
                    onChange={localForm.handleChange}
                >
                {opcionesGrupo}
                </NativeSelect>
            </FormControl>

            {AlertaValidacion}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Container>
  )
}

function InitialValues (init) {
  return {
    nombre: init.nombre,
    abrev: init.abrev,
    id_grupo: init.id_grupo
  }
}

function ValidacionSingin () {
  return Yup.object({
    nombre: Yup.string().required('El campo es obligatorio'),
    abrev: Yup.string().required('El campo es obligatorio').min(3, 'Debe ser de al menos 3 caracteres').max(6, 'Debe contener hasta 6 caracteres')
  })
}
